import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { companyStore, userToolStore, mainStore, feriadoStore } from '@/store';
import _ from 'lodash';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import TableHistoric from '@/views/main/DrivingMarketing/TableHistoric.vue';
let CompanyList = class CompanyList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome Fantasia', value: 'nome_fantasia' },
            { text: 'Status', value: 'status_id' },
            { text: 'Razão Social', value: 'razao_social' },
            { text: 'Representante Legal', value: 'representante_legal' },
            { text: 'Usuário responsável', value: 'user_responsavel' },
            { text: 'UF', value: 'uf' },
            { text: 'CNPJ', value: 'cnpj' },
            // { text: 'Total de tarefas', value: 'total_tarefas' },
            // { text: 'Total de parcerias', value: 'total_parcerias' },
            // {
            //   text:
            //     this.userProfile.company.escola_id == 2
            //       ? 'Total de Check-in'
            //       : 'Total de prospex',
            //   value: 'total_prospex',
            // },
            // { text: 'Total de alunos na van', value: 'total_aluno_van' },
            // { text: 'Total de alunos', value: 'total_aluno' },
            // { text: 'Total de contratos', value: 'total_contrato' },
            // {
            //   text: 'Total de alunos em turma (Status Vigente)',
            //   value: 'total_aluno_em_turma',
            // },
            // { text: 'Total de colaboradores', value: 'total_colaborador' },
            // { text: 'Quantidade de turmas', value: 'total_turma' },
            // { text: 'Total de presenças', value: 'total_aula_presenca' },
            // { text: 'Total de caixas', value: 'total_caixa' },
            // { text: 'Total de professores', value: 'total_professor' },
            {
                text: 'Bry - Status da coleta',
                value: 'bry_contrato_status',
            },
            {
                text: 'Data primeira parcela',
                value: 'financeiro_data_primeira_parcela',
            },
            { text: 'Valor parcela', value: 'valor_parcela' },
            { text: 'Ultimo log', value: 'log_ultimo_data_hora' },
            { text: 'Ativa', value: 'is_active' },
        ];
        this.financeiroHeaders = [
            { text: 'Unidade', value: 'unidade_nome_fantasia' },
            { text: 'Valor', value: 'valor_integral' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Qtd dias atraso', value: 'qtd_dias_atraso' },
            { text: 'Ultimo log', value: 'log_ultimo_data_hora' },
            { text: '', value: 'actions' },
        ];
        this.statusMap = {
            12: 'Distratada',
            13: 'Implantada',
            14: 'Não contratada',
            15: 'Onboarding',
        };
        this.tab = 'tab_unidades';
        this.loadingGerarFeriados = false;
        this.anoFeriado = new Date().getFullYear();
        this.dialogFeriados = false;
        this.search = '';
        this.selectedItem = {};
        this.pixDados = null;
        this.pixShowDialog = false;
        this.financeiroParcelaSelected = null;
        this.loading = true;
        this.loadingEmAtraso = false;
        this.exibirSomenteAtivas = true;
        this.financeiroParcelaAtraso = [];
    }
    get itemsFiltered() {
        return _.filter(this.companies, (item) => {
            if (this.exibirSomenteAtivas) {
                if (item.is_active) {
                    return item;
                }
            }
            else {
                return item;
            }
        });
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Unidade) {
            return userToolStore.userToolsIndexed.Unidade[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Unidade) {
            return userToolStore.userToolsIndexed.Unidade[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Unidade) {
            return userToolStore.userToolsIndexed.Unidade[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Unidade) {
            return userToolStore.userToolsIndexed.Unidade[0].allow_delete;
        }
    }
    async carregarFinanceiroParcelaAtraso() {
        this.loadingEmAtraso = true;
        this.financeiroParcelaAtraso =
            await companyStore.getCompaniesFinanceiroParcelaAtraso();
        this.loadingEmAtraso = false;
    }
    getDaysDiff(d1) {
        const dataD1 = new Date(d1);
        const dataD2 = new Date(new Date().toISOString().split('T')[0]);
        const diff = dataD2.getTime() - dataD1.getTime();
        const diffDays = diff / (1000 * 3600 * 24);
        // @ts-ignore
        return diffDays.toFixed(0);
    }
    showCompany(item) {
        this.selectedItem = item;
        const form = this.$refs.show;
        form.show(item.id);
    }
    get companies() {
        // @ts-ignore
        return companyStore.companies;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async showPix(item) {
        // @ts-ignore
        const form = this.$refs.PixIntegradorPagamentoDialog;
        item.syngoo_mensagem_id = 17;
        form.show(item);
    }
    async handleGerarAquivoFeriadoAno() {
        this.loadingGerarFeriados = true;
        await feriadoStore.gerarAquivoFeriadoAno(this.anoFeriado);
        this.loadingGerarFeriados = false;
    }
    async mounted() {
        this.loading = true;
        await companyStore.getCompanies();
        this.loading = false;
    }
};
CompanyList = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            PixIntegradorPagamentoDialog,
            TableHistoric,
        },
    })
], CompanyList);
export default CompanyList;
